import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2 className="font-bold">Polityka Prywatności</h2>
      <p className="mb-3 mt-5">Data: 1/10/2023</p>
      <p className="mb-3">
        Niniejsza Polityka Prywatności określa zasady zbierania, przetwarzania i
        ochrony danych osobowych użytkowników naszej strony internetowej
        naszapka.pl. Prosimy o uważne zapoznanie się z jej treścią, aby
        dowiedzieć się, jakie dane osobowe zbieramy i w jaki sposób są one
        wykorzystywane.
      </p>

      <p className="mb-3">
        Rodzaje Danych Osobowych
        <br />
        Na naszej stronie internetowej zbieramy następujące rodzaje danych
        osobowych:
      </p>
      <ul>
        <li>
          Adres e-mail: Używamy go do kontaktowania się z użytkownikami, np. w
          celu przesyłania informacji o naszych usługach lub produktach.
        </li>
        <li>
          Nick: Zbieramy nicki, które użytkownicy podają na naszej stronie.
        </li>
        <li>
          Linki do LinkedIn/Portfolio/Bio: Zbieramy publiczne linki do profili
          użytkowników na platformie LinkedIn lub portfolio online o ile podane
          przez użytkownika.
        </li>
      </ul>
      <p className="mb-3">
        Cel i Sposób Przetwarzania Danych Osobowych
        <br />
        Dane osobowe zbierane na naszej stronie są przetwarzane w celu:
      </p>
      <ul>
        <li>
          Komunikacji z użytkownikami w celu wysyłania informacji o dalszych
          krokach rozwoju platformy.
        </li>
      </ul>
      <p className="mb-3">
        Okres Przechowywania Danych Osobowych
        <br />
        Dane osobowe przechowujemy przez okres niezbędny do realizacji celów,
        dla których zostały zebrane. Po zakończeniu celu dane są usuwane.
      </p>
      <p className="mb-3">
        Udostępnianie Danych Osobowych
        <br />
        Dane osobowe użytkowników nie będą udostępniane podmiotom trzecim.
      </p>
      <p className="mb-3">
        Prawa Użytkowników
        <br />
        Użytkownicy mają prawo do dostępu do swoich danych osobowych, ich
        poprawiania, usuwania lub ograniczenia przetwarzania. Mają także prawo
        do wniesienia skargi do organu nadzorczego ds. ochrony danych osobowych.
      </p>
      <p className="mb-3">
        Cookies
        <br />
        Nasza strona internetowa może używać plików cookies w celu poprawy jej
        działania. Użytkownik ma możliwość zarządzania ustawieniami dotyczącymi
        cookies w swojej przeglądarce.
      </p>
      <p className="mb-3">
        Zmiany w Polityce Prywatności
        <br />
        Polityka Prywatności może być aktualizowana w związku z wprowadzaniem
        zmian na naszej stronie internetowej lub zmianami przepisów prawa.
        Prosimy o regularne sprawdzanie treści polityki.
      </p>
      <p className="mb-3">
        Kontakt
        <br />W przypadku pytań lub wątpliwości dotyczących naszej Polityki
        Prywatności prosimy o kontakt pod adresem kontakt@naszapka.pl.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
