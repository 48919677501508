import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import PrivacyPolicy from "./politykaPrywatnosci";
import Modal from "./modalComp";

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };
  useEffect(() => {
    const cookieAccepted = Cookies.get("cookieAccepted");
    if (cookieAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieAccepted", "true", { expires: 365 }); // Set the cookie to expire in 365 days
    setAccepted(true);
  };

  return (
    <div className={`cookie-banner mt-4 ${accepted ? "hidden" : ""}`}>
      {showModal && (
        <Modal handleClose={handleClose}>
          <PrivacyPolicy />
        </Modal>
      )}
      <p className="text-sm">
        Ta strona używa plików cookie, aby zapewnić Ci najlepszą jakość
        przeglądania.{" "}
        <a onClick={handleShow} className="italic" href="#">
          Polityka prywatności
        </a>
      </p>
      <button
        className="text-sm bg-blue-500 rounded-lg text-white p-2 mt-2"
        onClick={handleAccept}
      >
        Akceptuj
      </button>
    </div>
  );
};

export default CookieBanner;
