import db from "../firebase_setup/firebase";
import { useState, useEffect } from "react";
import { arrayUnion, doc, updateDoc } from "@firebase/firestore";
import {
  signUpWithEmailAndPassword,
  signOutUser,
} from "../firebase_handlers/authUsersHandlers";

const ZespolComp = () => {
  const [linkedin, setLinkedin] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [bio, setBio] = useState("");
  const [position, setPosition] = useState("");

  const updateFirestoreCollectionMsgs = async (
    collection,
    document,
    arrayKeyName,
    data
  ) => {
    try {
      const docRef = doc(db, collection, document);
      const updateData = { [arrayKeyName]: arrayUnion(data) };
      await updateDoc(docRef, updateData);
    } catch (error) {
      console.error(`Error updating the ${arrayKeyName} array:`, error);
    }
  };

  const handleChangeLinkedin = (e) => {
    setLinkedin(e.target.value);
  };

  const handleChangePortfolio = (e) => {
    setPortfolio(e.target.value);
  };

  const handleChangeBio = (e) => {
    setBio(e.target.value);
  };
  const handleChangePosition = (e) => {
    setPosition(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFirestoreCollectionMsgs("zespol", "n5CqprrcGd5gVTWIek1B", "subs", {
      bio,
      portfolio,
      linkedin,
      position,
    });

    setBio("");
    setPortfolio("");
    setLinkedin("");
    setPosition("");
  };

  return (
    <div className="mt-4">
      <div
        className="flex items-center justify-center 
      "
      >
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="flex flex-col">
              <input
                type="text"
                id="position"
                placeholder="Jaka rola Cię interesuje?"
                className="w-60 bg-blue-100 p-2 rounded-lg"
                value={position}
                onChange={handleChangePosition}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="linkedin"
                placeholder="LinkedIn"
                className="w-60 bg-gray-100 p-1 rounded-lg"
                value={linkedin}
                onChange={handleChangeLinkedin}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="portfolio"
                placeholder="Portfolio"
                className="w-60 bg-gray-100 p-1 rounded-lg"
                value={portfolio}
                onChange={handleChangePortfolio}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="bio"
                placeholder="Bio"
                className="w-60 bg-gray-100 p-1 rounded-lg"
                value={bio}
                onChange={handleChangeBio}
              />
            </div>
          </div>

          <button className="bg-blue-500 text-white py-2 px-4 rounded-full mt-4 hover:bg-blue-600">
            Wyślij
          </button>
        </form>
      </div>
    </div>
  );
};

export default ZespolComp;
