import { getDoc, arrayUnion, doc, updateDoc } from "@firebase/firestore";
import db from "../firebase_setup/firebase";

const firestoreFetchCollection = async (colection, document) => {
  try {
    const categoriesSnapshot = await getDoc(doc(db, colection, document));
    const categoriesData = categoriesSnapshot.data();
    return categoriesData || [];
  } catch (error) {
    console.error("Error fetching document", error);
  }
};

//updates firestore with email and nick
const updateFirestoreCollection = async (
  collection,
  document,
  arrayKeyName,
  data
) => {
  try {
    const docRef = doc(db, collection, document);
    const updateData = { [arrayKeyName]: arrayUnion(data) };
    await updateDoc(docRef, updateData);
    
  } catch (error) {
    console.error(`Error updating the ${arrayKeyName} array:`, error);
  }
};

const updateSubsArray = async (
  collectionName,
  documentId,
  updatedSubsArray
) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await updateDoc(docRef, {
      subs: updatedSubsArray, // Replace with your actual array field name
    });
  
  } catch (error) {
    console.error("Error updating subs array:", error);
  }
};

export { firestoreFetchCollection, updateFirestoreCollection, updateSubsArray };
