import ReactDOM from "react-dom";
import { useEffect, useState } from "react";

function Modal({ handleClose, children }) {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return ReactDOM.createPortal(
    <div>
      <div
        onClick={handleClose}
        className="fixed inset-0 bg-gray-300 opacity-80"
      ></div>
      <div className="fixed inset-40 p-10 bg-white overflow-y-auto">
        <div className="flex flex-col justify-between h-full max-h-96 text-sm">
          {children}
        </div>
      </div>
    </div>,
    document.querySelector(".modal-container")
  );
}

export default Modal;
