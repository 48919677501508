import { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { doc, getDoc } from "@firebase/firestore";
import db from "../firebase_setup/firebase";
import QR from "./qrComp";

const GenerateLinks = ({ onUIDChange }) => {
  const [email, setEmail] = useState("");
  const [notificationOK, setNotificationOK] = useState(null);
  const [notificationEr, setNotificationEr] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [UID, setUID] = useState(null);

  const firestoreFetchCollection = async (colection, document) => {
    try {
      const categoriesSnapshot = await getDoc(doc(db, colection, document));
      const categoriesData = categoriesSnapshot.data();
      return categoriesData || [];
    } catch (error) {
      console.error("Error fetching document", error);
    }
  };

  const handleEmailChange = (e) => {
    let lowerCaseEmail = e.target.value.toLowerCase();
    setEmail(lowerCaseEmail);
  };

  const handleGenerateClick = async () => {
    const array = await firestoreFetchCollection(
      "dolacz1",
      "JtlaeAju84a3kC7O134y"
    );

    const user = array.subs.find((user) => user.email === email);
    if (user) {
      setNotificationOK("sukces!");
      setNotificationEr(null);
      setDisabled(true);
      setUID(user.userID);
      onUIDChange(user.userID);
    } else {
      setNotificationEr("ups..nie znaleźliśmy twojego email!");
      setNotificationOK(null);
    }
  };

  useEffect(() => {
    const notificationTimer = setTimeout(() => {
      setNotificationOK(null);
      setNotificationEr(null);
    }, 5000);

    return () => clearTimeout(notificationTimer); // Clear the timer on component unmount
  }, [notificationOK, notificationEr]);

  return (
    <div>
      {disabled ? (
        <p className="text-emerald-600 mt-2">{notificationOK}</p>
      ) : (
        <div>
          <p className="text-rose-600 mt-2">{notificationEr}</p>
          <input
            className="p-1 border border-gray-300 rounded  text-black focus"
            placeholder="Twój email"
            value={email}
            onChange={handleEmailChange}
          />
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-full  hover:bg-blue-600"
            onClick={handleGenerateClick}
          >
            Generuj moje linki
          </button>
        </div>
      )}
    </div>
  );
};

const ShareButtons = () => {
  const [UIDFromGenerateLinks, setUIDFromGenerateLinks] = useState("");

  let shareUrl = `https://naszapka.pl/?utm_id=${UIDFromGenerateLinks}`;
  // const title = "Dołącz i zostań Lokalesem! PeopleApp";

  const handleUIDChange = (uid) => {
    setUIDFromGenerateLinks(uid);
  };

  return (
    <div>
      <GenerateLinks onUIDChange={handleUIDChange} />
      {UIDFromGenerateLinks === "" ? null : (
        <div className="mt-4">
          <p className="text-lg mb-1 text-slate-800 font-medium font-semibold  ">
            To jest twój link:
          </p>
          <p className="text-sm text-slate-600 italic mb-1">
            Udostępnij go w post na Facebooku, X, email i gdzie tylko chcesz!
          </p>
          <div className=" p-1 text-slate-200 border-dashed border-2 border-pink-700 rounded-lg flex flex-wrap space-x-4 justify-center text-sm mb-2 ">
            {shareUrl}
          </div>
          <QR data={shareUrl} />
        </div>

        // <div className="mt-4">
        //   <p className="text-lg text-black ">Podziel się na:</p>
        //   <div className="flex flex-wrap space-x-4 justify-center">
        //     <a
        //       href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&utm_source=facebook&utm_medium=social`}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //       className="text-black text-3xl hover:text-red-500 hover:bg-blue-100 rounded-full p-2 transition-all duration-300"
        //     >
        //       <FaFacebook className="inline-block" />
        //     </a>
        //     <a
        //       href={`https://twitter.com/share?url=${shareUrl}&utm_source=x&utm_medium=social&text=${title}`}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //       className="text-black text-3xl hover:text-red-500 hover:bg-blue-100 rounded-full p-2 transition-all duration-300"
        //     >
        //       <FaXTwitter className="inline-block" />
        //     </a>
        //     <a
        //       href={`https://www.instagram.com/stories/my/story?url=${shareUrl}&utm_source=instagramk&utm_medium=social`}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //       className="text-black text-3xl hover:text-red-500 hover:bg-blue-100 rounded-full p-2 transition-all duration-300"
        //     >
        //       <FaInstagram className="inline-block" />
        //     </a>
        //     <a
        //       href={`mailto:?subject=${title}&body=Sprawdź tę niesamowitą stronę: ${shareUrl}&utm_source=email&utm_medium=social`}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //       className="text-black text-3xl hover:text-red-500 hover:bg-blue-100 rounded-full p-2 transition-all duration-300"
        //     >
        //       <FaEnvelope className="inline-block" />
        //     </a>
        //   </div>
        // </div>
      )}
    </div>
  );
};

export default ShareButtons;
