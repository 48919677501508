import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const auth = getAuth();

const signUpWithEmailAndPassword = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return "ok";
  } catch (error) {
    // Obsłuż błąd rejestracji.
    console.error("Błąd rejestracji:", error);

    if (error.code === "auth/email-already-in-use") return "inUse";
    if (error.code === "auth/invalid-email") return "invalidEmail";
  }
};

const signOutUser = async () => {
  try {
    await signOut(auth);
    // Użytkownik został wylogowany pomyślnie.
  } catch (error) {
    // Obsłuż błąd wylogowywania.
    console.error("Błąd wylogowywania:", error);
  }
};
const getUserId = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user.uid); // Jeśli użytkownik jest zalogowany, zwróć UID
      } else {
        reject(new Error("Użytkownik niezalogowany")); // W przeciwnym razie rzuć błąd
      }
      unsubscribe(); // Zakończ nasłuchiwanie po uzyskaniu UID
    });
  });
};

export { signUpWithEmailAndPassword, signOutUser, getUserId };
