import { analytics, logEvent } from "../firebase_setup/firebase";
import { useState, useEffect } from "react";
import {
  signUpWithEmailAndPassword,
  signOutUser,
  getUserId,
} from "../firebase_handlers/authUsersHandlers";
import {
  firestoreFetchCollection,
  updateFirestoreCollection,
  updateSubsArray,
} from "../firebase_handlers/firestoreHandlers";

const DolaczComp = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [emailMatch, setEmailMatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notifi, setNotifi] = useState(null);
  const [notifiErr, setNotifiErr] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [ifUserExist, setIfUserExist] = useState(null);
  const [joined, setJoined] = useState(false);

  useEffect(() => {
    if (success) {
      const notificationTimer = setTimeout(() => {
        setNotifi(null);
        setNotifiErr(null);
        setSuccess(false);
        setEmailError(false);
        setIfUserExist(null);
      }, 3000);

      return () => clearTimeout(notificationTimer); // Clear the timer on component unmount
    }
  }, [success]);

  const handleEmailChange = (e) => {
    let toLowerCase = e.target.value.toLowerCase();
    setEmail(toLowerCase);
  };

  const handleConfirmEmailChange = (e) => {
    let toLowerCase = e.target.value.toLowerCase();
    setConfirmEmail(toLowerCase);
  };
  const handleNicknameChange = (e) => {
    let toLowerCase = e.target.value.toLowerCase();
    setNickname(toLowerCase);
  };

  const generateDiscriminator = () => {
    return Math.floor(1000 + Math.random() * 9000); // Generates a random 4-digit number
  };

  useEffect(() => {
    if (email === confirmEmail && nickname !== "") {
      setEmailMatch(true);
    } else {
      setEmailMatch(false);
    }

    return;
  }, [email, confirmEmail, nickname]);

  useEffect(() => {
    if (ifUserExist === "inUse") {
      setNotifi("Twój email już z nami jest! :D");
      setSuccess(true);
    }
    if (ifUserExist === "invalidEmail") {
      setEmailError(true);
      setNotifiErr("Zły email, sprawdź format");
      setSuccess(true);
    }
  }, [ifUserExist]);

  function getUtmIdFromUrl(url) {
    const urlSearchParams = new URLSearchParams(new URL(url).search);
    return urlSearchParams.get("utm_id");
  }

  useEffect(() => {
    //referal program - score increase
    const utmid = getUtmIdFromUrl(window.location.href);

    const referal = async () => {
      if (utmid !== null) {
        //1 check if exists
        //1.a if no - add new record
        //1.b as below update
        const dolaczArr = await firestoreFetchCollection(
          "referal1",
          "hn8iT10zlO0tekwWkuGf"
        );

        const objectFind = dolaczArr.subs.find((obj) => obj.userID === utmid);

        if (objectFind !== undefined) {
          //update existing record/object
          const indexToUpdate = dolaczArr.subs.findIndex(
            (obj) => obj.userID === utmid
          );
          if (indexToUpdate !== -1) {
            // Update the object in the array
            dolaczArr.subs[indexToUpdate].count =
              parseInt(dolaczArr.subs[indexToUpdate].count, 10) + 1;

            await updateSubsArray(
              "referal1",
              "hn8iT10zlO0tekwWkuGf",
              dolaczArr.subs
            );
          } else {
            console.log(`No object found with userid`);
          }
        } else {
          await updateFirestoreCollection(
            "referal1",
            "hn8iT10zlO0tekwWkuGf",
            "subs",
            {
              userID: utmid,
              count: 1,
            }
          );
        }
      }
      setJoined(false);
    };
    if (joined) referal();
  }, [joined]);

  const handleButtonClick = async () => {
    try {
      let lowerCaseEmail = email.toLowerCase();
      let lowerCaseNick = nickname.toLowerCase();

      let nickDiscrim = `${lowerCaseNick}#${generateDiscriminator()}`;
      const signUp = await signUpWithEmailAndPassword(email, nickDiscrim);
      setIfUserExist(signUp);

      let userID = await getUserId();

      if (email === confirmEmail && nickname !== "") {
        // Send an event to Google Analytics when the button is clicked

        logEvent(analytics, "button_click", {
          button_id: "dolacz_button",
          event_label: "dolacz_button Clicked",
          page_url: window.location.href,
          user_id: userID,
        });

        await updateFirestoreCollection(
          "dolacz1",
          "JtlaeAju84a3kC7O134y",
          "subs",
          {
            email: lowerCaseEmail,
            nick: nickDiscrim,
            userID: userID,
          }
        );
        setSuccess(true);
        setNotifi("Sukces!!!");
        setEmail("");
        setConfirmEmail("");
        setNickname("");
        setEmailMatch(false);
        signOutUser();
        setJoined(true);
      }
    } catch (error) {
      if (ifUserExist === "inUse") {
        setNotifi("Twój email już z nami jest! :D");
        setSuccess(true);
      }
      if (ifUserExist === "invalidEmail") {
        setEmailError(true);
        setNotifiErr("Zły email, sprawdź format");
        setSuccess(true);
      }
    }
  };

  return (
    <div className="mt-4">
      {success ? (
        emailError ? (
          <p className="text-red-700 mt-2">{notifiErr}</p>
        ) : (
          <p className="text-emerald-700 mt-2">{notifi}</p>
        )
      ) : (
        <div>
          <input
            className="p-1 border border-gray-300 rounded text-black"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <input
            className="p-1 border border-gray-300 rounded  text-black"
            placeholder="Potwierdź Email"
            value={confirmEmail}
            onChange={handleConfirmEmailChange}
          />
          <input
            className="p-1 border border-gray-300 rounded  text-black"
            placeholder="Twój nick"
            value={nickname}
            onChange={handleNicknameChange}
          />
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded-full  hover:bg-blue-600 ${
              !emailMatch ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleButtonClick}
            disabled={!emailMatch}
          >
            Wyślij
          </button>
        </div>
      )}
    </div>
  );
};

export default DolaczComp;
