/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import close from "./Img/close.svg";
import menu from "./Img/menu.svg";
import step1 from "./Img2/1.jpg";
import step2 from "./Img2/2.jpg";
import step3 from "./Img2/3.jpg";
import zespol from "./Img2/zespol.png";
//mine
import { ReactComponent as MySVGImage } from "./Img2/mainSvg.svg";
import { ReactComponent as MySVGImage2 } from "./Img2/kontakt.svg";
import { useState, useEffect } from "react";
import F1 from "./Img2/F101.png";
import F2 from "./Img2/F222.png";
import F3 from "./Img2/F331.png";
import F4 from "./Img2/F771.png";
import F5 from "./Img2/F555.png";
import F6 from "./Img2/F6666.png";
import DolaczComp from "./components/dolaczComp";
import ZespolComp from "./components/zespolComp";
import CookieBanner from "./components/cookiesComp";
import ShareButtons from "./components/szerujKontentComp";
import { BsInfinity } from "react-icons/bs";

function App() {
  const [currentSection, setCurrentSection] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleMenuClick = (sectionId) => {
    // Obsługa kliknięcia w przycisk menu
    setCurrentSection(sectionId);

    // Przewijanie do wybranej sekcji
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      {/* <!--NAVIGATION BLOG START--> */}
      <div className="nav-bar">
        <div className="container">
          <div>
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="5" r="3" />
              <path d="M12 22s-4-1-4-6V8h8v8c0 5-4 6-4 6z" />
            </svg>
          </div>
          <img
            id="mobile-cta"
            className="mobile-menu"
            src={menu}
            alt="navigation"
            onClick={toggleMobileMenu}
          />
          <nav className={isMobileMenuOpen ? "show" : ""}>
            <img
              id="mobile-exit"
              className="mobile-menu-exit"
              src={close}
              alt="close navigation"
              onClick={toggleMobileMenu}
            />
            <ul className="primary-nav">
              <li className="current" onClick={() => handleMenuClick("home")}>
                <a href="#home">Home</a>
              </li>
              <li onClick={() => handleMenuClick("idea")}>
                <a href="#idea">Idea</a>
              </li>
              <li onClick={() => handleMenuClick("plan")}>
                <a href="#plan">Plan</a>
              </li>
              <li onClick={() => handleMenuClick("dolacz")}>
                <a href="#dolacz">Dołącz</a>
              </li>
              <li onClick={() => handleMenuClick("dolacz")}>
                <a href="#o-nas">O nas</a>
              </li>
            </ul>

            <ul className="second-nav">
              <li onClick={() => handleMenuClick("zespol")}>
                <a href="#zespol">Zespół-dołącz</a>
              </li>

              <li onClick={() => handleMenuClick("kontakt")}>
                <a href="#kontakt">B2B/Kontakt</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* <!--NAVIGATION BLOG END--> */}

      {/* HOME*/}
      <section id="home" className="hero">
        <div className="container">
          <div className="left-col">
            <p className="sub-head">Mapa możliwości</p>
            <h2>
              Twórz, Odkrywaj, Oferuj i Łącz się z ludzmi w twojej okolicy.
            </h2>
            <p className="mb-5 mt-2">
              Każdy wspierający staje się 'stakeholdrem' 🙌
            </p>
            <div className="hero-cta" onClick={() => handleMenuClick("idea")}>
              <a href="#" className="primery-cta">
                Czytaj więcej i dołącz
              </a>
            </div>
            <CookieBanner />
          </div>
          <div className="hero-img">
            {" "}
            <MySVGImage />
          </div>
        </div>
      </section>

      {/* HOME END--> */}

      {/* IDEA-> */}
      <section id="idea" className="hero2">
        <div className="container">
          <h2>Wzbogać lokalne otoczenie oraz korzystaj z potencjału innych!</h2>
          <h3>
            Domowe wypieki. Przeprowadzki. Lokalne alerty. Możliwości są
            nieskończone.
          </h3>
          <h4>👌Wymiana barter czy za środki pieniężne, zależy od was!</h4>
          <div className="feature-container mt-5">
            <div className="feature left">
              <img src={F1} alt="" className="hero-image border-1 shadow-xl" />
              <p className="feature-description text-justify ml-3">
                Rozwiń swój potencjał oferując lokalnie swoje usługi oraz
                produkty. Nawet nie wiesz jak wiele osób czeka na Ciebie!
              </p>
            </div>

            <div className="feature right">
              <p className="feature-description text-justify mr-3">
                Twórz i reaguj na lokalny popyt oraz podaż. Jeżeli czegoś
                potrzebujesz (np. przeprowadzka czy wymiana dętki w rowerze),
                zapostuj i czekaj na oddzew.
              </p>
              <img src={F2} alt="" className="hero-image border-1 shadow-xl" />
            </div>

            <div className="feature left">
              <img src={F3} alt="" className="hero-image border-1 shadow-xl" />
              <p className="feature-description text-justify ml-3">
                Przesiewaj oferty i odkrywaj idealne możliwości, aby pomóc lub
                skorzystać z usług.
              </p>
            </div>

            <div className="feature right">
              <p className="feature-description text-justify mr-3">
                Pamiętaj, że bezpośredni czat z autorem pozwoli Ci zdobyć
                dodatkowe informacje. Wszystko, czego potrzebujesz, jest na
                wyciągnięcie ręki!
              </p>
              <img src={F4} alt="" className="hero-image border-1 shadow-xl" />
            </div>

            <div className="feature left">
              <img src={F5} alt="" className="hero-image border-1 shadow-xl" />
              <p className="feature-description text-justify ml-3">
                Oznaczaj alerty powiadomień, aby lokalna społeczność mogła
                odpowiednio zareagować. Zdobywaj przy tym punkty zaufania!
              </p>
            </div>

            <div className="feature right">
              <p className="feature-description text-justify mr-3">
                Twoje ulubione oferty zawsze pod ręką. Nie czekaj dłużej,
                zacznij budować lokalne relacje i interesy już teraz!
              </p>
              <img src={F6} alt="" className="hero-image border-1 shadow-xl" />
            </div>
          </div>
        </div>
      </section>

      {/* IDEA END--> */}
      {/* <!--SECTION FEATURES BLOG START--> */}
      <section id="plan" className="hero3">
        <div className="container">
          <h2>Główne kamienie milowe w dużym skrócie</h2>
          <h3>
            Zbliżając się do końca, będziemy sukcesywnie udostępniać coraz
            więcej informacji. Naszym celem jest, abyście czuli się komfortowo z
            naszymi planami i nie zostali przytłoczeni ich ilością 😎
          </h3>

          <div className=" text-white py-16">
            <div className="container mx-auto">
              <h1 className="text-4xl font-bold mb-6"></h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {/* Milestone 1 */}
                <div className="bg-white rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300 ease-in-out border-2 border-green-500">
                  {/* <span className="bg-blue-500 text-white px-2 py-1 absolute top-0 left-0 transform -translate-x-6 translate-y-1 -rotate-45">
                     Zrobione
                       </span> */}
                  <div className="bg-blue-500 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                    1
                  </div>
                  <h2 className="text-xl mb-3 text-black">Prototyp Apki</h2>
                  <p className="text-gray-600">
                    Dostarczenie produktu MVP (Minimum Viable Product), czyli
                    wersji z podstawowymi funkcjonalnościami aplikacji, pozwoli
                    nam szybko pozyskać Waszą opinię. Dzięki temu będziemy mogli
                    doskonalić i dostosować finalny produkt do Waszych potrzeb.
                  </p>
                  <p className="bg-pink-500 p-2 mt-2 rounded-lg">
                    <span className="font-semibold">
                      Runda 1<br />
                    </span>
                    Zbieraj punkty udziałowca.
                  </p>
                  <p className="mt-5 text-sm text-gray-500 ">
                    <span className="font-semibold  "> Data zakończenia:</span>
                    <br /> Listopad / Grudzień 2023
                  </p>
                </div>
                {/* Milestone 2 */}
                <div className="bg-white rounded-lg p-6 text-center  transform hover:scale-105 transition-transform duration-300 ease-in-out">
                  <div className="bg-blue-500 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4 ">
                    2
                  </div>
                  <h2 className="text-xl mb-3 text-black">Wersja Beta</h2>
                  <p className="text-gray-600">
                    Tutaj dostarczymy wersję beta aplikacji webowej i mobilnej.
                    Każdy, kto dołączy, będzie miał okazję do testowania i
                    współpracy nad jej udoskonaleniem. 🙌
                  </p>
                  <p className=" bg-pink-300 p-2 mt-2 rounded-lg">
                    <span className="font-semibold">
                      Runda 2<br />
                    </span>
                    Dozbieraj więcej punktów udziałowca.
                  </p>

                  <p className="mt-5 text-sm text-gray-500">
                    <span className="font-semibold"> Data zakończenia:</span>
                    <br /> Luty / Marzec 2024
                  </p>
                </div>
                {/* Milestone 3 */}
                <div className="bg-white rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300 ease-in-out">
                  <div className="bg-blue-500 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                    3
                  </div>
                  <h2 className="text-xl mb-3 text-black">💥Finalna Apka🚀</h2>
                  <p className="text-gray-600">
                    Po zakończeniu rundy testów, planujemy oficjalne
                    wprowadzenie aplikacji na platformy mobilne i webowe. Teraz
                    nadejdzie era wolności i niezależności!
                  </p>
                  <p className=" bg-pink-300 p-2 mt-2 rounded-lg">
                    <span className="font-semibold">
                      Runda bonus <br />
                    </span>
                    Dozbieraj jeszcze więcej punktów!
                  </p>

                  <p className="mt-5 text-sm text-gray-500">
                    <span className="font-semibold"> Data zakończenia:</span>
                    <br /> Kwiecień 2024
                  </p>
                </div>
                {/* Milestone 4 */}
                <div className="bg-white rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300 ease-in-out">
                  <div className="bg-blue-500 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                    4
                  </div>
                  <h2 className="text-xl mb-3 text-black">Tryb operacyjny</h2>
                  <p className="text-gray-600 ">
                    Nadszedł czas na oficjalne przejście w fazę operacyjną.{" "}
                    <br />
                    Nowa strona, nowe cele 🚀 z nami w stronę gwiazd!
                    <p className="mt-3 font-semibold"></p>
                  </p>
                  <p
                    className=" bg-pink-300 p-2 mt-2 rounded-lg text-sm
                "
                  >
                    <span className="font-semibold">
                      Podsumowanie
                      <br />
                    </span>
                    Przeliczanie 'punktów udziałowca' na akcje w naszej
                    platformie.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="aktualnosci mb-4 text-justify">
            <h3 className="font-semibold mb-2">Nasz obecny postęp</h3>
            <ul className="list-disc pl-6">
              <li>
                Obecnie dostarczyliśmy MVP (Minimum Viable Product), czyli punkt
                1.
              </li>
              <li>
                Teraz oczekujemy na wasze ankiety z feedbackiem i szykujemy się
                do wersji beta!
              </li>
              <li>
                Co ważne! W obecnej rundzie 1, nagradzamy podwójnie osoby, które
                dołączą i polecą nas dalej.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* <!--SECTION FEATURES BLOG END--> */}
      {/* <!--SECTION2 TEST  BLOG START--> */}
      <section id="dolacz" class="test-monials-section">
        <div className="container">
          <h2>Zaangażuj się i zostań współtwórcą naszej apki. Waszej apki!</h2>
          <div>
            <h3 className="mb-3">
              <span role="img" aria-label="Thumbs up">
                👍
              </span>{" "}
              Zdobądź poniższe levele, aby zgarnąć punkty udziałowca.
            </h3>
            <h3>
              <span role="img" aria-label="Thumbs up">
                👍
              </span>{" "}
              Nie ma górnego limitu punktów, więc to Ty decydujesz.
            </h3>

            <div className="container mx-auto p-4">
              <h1 className="text-2xl font-bold mb-4 relative ">
                Przelicznik udziałowca
                <span className="  bg-pink-500 rounded-lg text-white px-2 py-1 absolute top-0 left-0 transform -translate-x-12 -translate-y-11 -rotate-45">
                  Runda 1
                </span>
              </h1>

              <div className="mb-4">
                <p className="mb-2">
                  Udziały per 1 punkt ={" "}
                  <span className="text-lg font-bold">
                    <span className="text-black	">
                      💲 udziały przeznaczone dla wspierających
                    </span>{" "}
                    /{" "}
                    <span className="text-black	">
                      łączna ilość punktów{" "}
                      <span className="text-amber-400">udziałowca</span>{" "}
                      zgromadzona przez Was
                    </span>
                  </span>
                </p>
              </div>
              <div>
                <p className="mb-2">
                  Udziały per osoba ={" "}
                  <span className="text-lg font-bold">
                    <span className="text-black	">Udziały per 1 punkt</span> ✖{" "}
                    <span className="text-black	">zgromadzone punkty </span>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-20 text-center ">
            <div className="testimonials-list">
              <ul>
                <li className="bg-white p-4 rounded-lg shadow-md my-4 border">
                  <img
                    src={step1}
                    alt=""
                    className="mx-auto rounded-full border"
                  />

                  <h3 className="font-bold text-lg mt-4">
                    level 1
                    <br /> Dołącz!
                  </h3>

                  <p className="mt-2 bg-blue-500 rounded-lg font-light">
                    Zostaw nam swój adres e-mail!
                  </p>
                  <p className="mt-2 bg-blue-500 rounded-lg font-light">
                    Wylosujemy grupę osób do testów w celu zebrania feedbacku
                    odnośnie funkcjonalności. Będzie nam miło jeżeli pomożecie
                    😎
                  </p>
                  <div className="mt-4">
                    <p className="bg-pink-500 rounded-lg">
                      Zgarniasz: 2 punkty
                    </p>
                    <p className="mt-2 text-xs">
                      Punkty zostaną uwzględnione dopiero po zdobyciu levelu 2.
                    </p>
                  </div>

                  <DolaczComp />
                </li>

                <li className="bg-white p-4 rounded-lg shadow-md my-4 border">
                  <img
                    src={step2}
                    alt="Pricing Plan 2"
                    className="mx-auto rounded-full border"
                  />
                  <h3 className="font-bold text-lg mt-4">
                    level 2<br /> Udostępniaj!
                  </h3>
                  <p className="mt-2 bg-blue-500 rounded-lg font-light">
                    Udostępniając, zdobywasz kolejne punkty, które nazywamy
                    "punkty udziałowca".
                  </p>
                  <p className="mt-2 bg-blue-500 rounded-lg font-light">
                    Za każdą osobę, która kliknęła w twój indywidualny link
                    poleceń i dołączyła, naliczymy dodatkowe punkty.
                  </p>

                  <div className="mt-4">
                    <p className="bg-pink-500 rounded-lg">
                      Zgarniasz: 4 punkty + xxx konwersji 🙋‍♀️
                    </p>

                    <p className="mt-2 text-xs">
                      Każda osoba, która dołączy (konwersja) za pośrednictwem
                      twojego udostępnienia = 2 punkty.
                    </p>
                  </div>

                  <p className="">
                    <ShareButtons />
                  </p>
                </li>

                <li className="bg-white p-4 rounded-lg shadow-md my-4 border relative">
                  <div className="coming-soon-overlay absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white rounded-lg">
                    <p className="text-3xl font-bold">Planowane na runde 2</p>
                  </div>
                  <img
                    src={step3}
                    alt="Pricing Plan 2"
                    className="mx-auto rounded-full border"
                  />
                  <h3 className="font-bold text-lg mt-4">
                    level 3<br /> Dołóż się
                  </h3>
                  <p className="mt-2">
                    <p className="mt-2 bg-blue-500 rounded-lg font-light">
                      Za każdy 1 PLN, otrzymujesz 1 punkt{" "}
                      <span className="text-amber-400">udziałowca</span>!
                    </p>
                    <p className="mt-2 bg-blue-500 rounded-lg font-light">
                      Fundusze są przypisane do rozwoju aplikacji, dążąc do
                      osiągnięcia jak najwyższej jakości finalnego produktu!
                    </p>
                    <p className="mt-2 bg-blue-500 rounded-lg font-light">
                      Ilość wpłaconych środków będzie bezpośrednio wpływać na
                      Twój udział w projekcie.
                    </p>
                  </p>
                  <div className="mt-4">
                    <p className="bg-pink-500 rounded-lg ">
                      Zgarniasz: 🚀 xxx PLN!
                    </p>

                    <p className="mt-2 text-xs">
                      Uwzględniamy łączną sumę wszystkich Twoich wpłat. Warto
                      podać ten sam email, co w Level 1, abyśmy mogli Cię
                      znaleźć!
                    </p>
                  </div>
                  <button className="bg-blue-500 text-white py-2 px-4 rounded-full mt-4 hover:bg-blue-600">
                    Link do zrzutki
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!--SECTION TEST  BLOG END--> */}
      <section id="zespol" className="hero3">
        <div className="container">
          <img src={zespol} alt="" className="mx-auto w-1/4 rounded-full" />
          <h3 className="font-bold text-xl mt-4 mb-7">Zespół</h3>
          <p className="text-justify">
            Czy pragniesz bezpośredniego wpływu na rozwój naszej platformy?
            Czujesz, że masz wiele do zaoferowania i chciałbyś być częścią tej
            dynamicznej i kreatywnej społeczności? Śmiało się z nami skontaktuj!
            Podziel się swoim linkiem do Bio/LinkedIn/Portfolio.
          </p>
          <p className="mt-4 mb-4 font-semibold">Jeżeli jesteś:</p>
          <div className="text-justify">
            <p className="mb-1">
              💥 Doświadczonym React.js & React Native developerem
            </p>
            <p className="mb-1">💥 Specjalistą od Marketingu & Social Media</p>
            <p className="mb-1">
              💥 Prawnikiem / Doradcą podatkowym z praktycznym doświadczeniem
              startup-owym
            </p>

            <br />
          </div>

          <p className="mt-4 font-semibold">
            Podeślij nam coś poniżej. Jak Ci pasuje!
          </p>
          <ZespolComp />
        </div>{" "}
      </section>

      {/* ONAS */}
      <section id="o-nas" className="hero2">
        <div className="container text-justify ">
          <h2 className="text-3xl font-semibold mb-7">Parę słów od nas 😘!</h2>
          <div className="paragraph1 mb-5 ">
            <p className="">
              Czy kiedykolwiek zastanawiałeś się, dlaczego niektórzy stają się
              coraz bogatsi, podczas gdy inni tracą?
            </p>
          </div>
          <div className="paragraph2 mb-5">
            <p className="">
              Nasz obecny system ekonomiczny często premiuje korporacje i
              wielkie przedsiębiorstwa, nie zawsze dbając o dobrobyt i rozwój
              lokalnych społeczności.
            </p>
          </div>
          <div className="paragraph3 mb-5">
            <p className="italic">
              Nasza aplikacja to narzędzie, które łączy lokalną podaż i popyt na
              dobra oraz usługi.
              <br /> To Ty, oferując swoje umiejętności czy produkty, sprawiasz
              że społeczność ludzi staje się bardziej niezależna. <br />
              Czy to będzie barter (wymiana) czy rękompensata pieniężna - zależy
              od waszych aktualnych potrzeb!
            </p>
          </div>
          <div className="paragraph4 mb-5">
            <p className="">
              Co więcej,{" "}
              <a class="underline decoration-pink-500">
                każdy użytkownik naszej aplikacji staje się współwłaścicielem
              </a>
              . Działamy zgodnie z zasadą 'win-win' oraz w duchu solidarności
              międzyludzkiej. Dlatego przez cały okres życia NASZEJ platformy
              będziemy nagradzać aktywnych użytkowników, a wspierający nas od
              początku będą cieszyć się znacznie większą pulą!
            </p>
          </div>
          <div className="cel mb-4">
            <h3 className=" font-semibold mb-2">Cel:</h3>
            <p className="">
              Dostarczenie platformy do budowania lokalnego rynku dóbr i usług
              aby wspierać międzyludzką niezależność i przedsiębiorczość.
            </p>
          </div>
          <div className="mantra mb-4">
            <h3 className=" font-semibold mb-2">Nasza mantra:</h3>
            <p className="">
              Klucz do sukcesu to umiejętność dzielenia się i zaangażowanie
              uczestników. Dlatego oddajemy część udziałów aktywnym uczestnikom
              (zwłaszcza early-birds wspierający od początku). Im bardziej
              rośniemy tym więcej zyskujemy.
            </p>
          </div>
        </div>
      </section>

      {/* <!--SECTION CONTACT  BLOG START--> */}
      <section id="kontakt" class="contact-section">
        <div className="container flex ">
          <div className="contact-left w-1/2">
            <h2 className="">Napisz do nasz</h2>

            <p className="mt-5">
              Jesteśmy bardzo zajęci, dlatego odpowiemy po godzinach ciężkiej
              pracy. Daj nam znać, czego dusza pragnie!
            </p>
            <p className="mt-3 mb-8">
              Niedługo uruchomimy media społecznościowe, co ułatwi naszą
              komunikację. Na chwilę obecną korzystajcie z poniższych adresów.
            </p>
            <div className="mt-3 ">
              <h3 className="font-semibold">Współpraca B2B:</h3>
              <p>Chcesz nawiązać z nami współprace? Napisz!</p>
              <h3 className="text-sm tracking-widest">b2b@naszapka.pl</h3>
            </div>
            <div className="mt-5 ">
              <h3 className="font-semibold">Osoba fizyczna:</h3>
              <p>
                Nurtujące pytanie? Pisz a postaramy się odpisać najszybciej jak
                możemy!
              </p>
              <h3 className="text-sm tracking-widest">kontakt@naszapka.pl</h3>
            </div>
            <div className="mt-5 ">
              <h3 className="font-semibold">Zespół:</h3>
              <p>Masz pytanie lub chcesz podesłać coś więcej, napisz.</p>
              <h3 className="text-sm tracking-widest">zespol@naszapka.pl</h3>
            </div>
          </div>

          <div className="contact-right w-1/2">
            <MySVGImage2 />
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
