import React from "react";
import QRCode from "qrcode.react";

const QR = ({ data }) => {
  // Dane, które mają zostać zamienione na QR kod

  return (
    <div className="flex items-center justify-center">
      {/* Komponent QRCode przyjmuje wiele propsów, np. value to dane do zamiany na QR kod */}
      <QRCode value={data} size={128} />
    </div>
  );
};

export default QR;
